.bubble {
    position: fixed;
    z-index: 1000;
}

.bubble.top{
  top:120px;
  right:.5rem;
}

.bubble.right{
    right: 0.5rem;
    bottom: 0.25rem;
}
.bubble.bottom{
    right: 0.5rem;
    bottom: 0.85rem;
}

.bubble:not(.instant){
    opacity: 0;
    animation: bounceIn .5s linear 10s forwards, bounceOut .5s linear 12s forwards;
}

.bubble div {
	position: relative;
	background: rgba(0,0,0,.85);
    color:#fff;
    padding:.85rem 1.25rem;
}

.bubble div:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    right: -4px;    
    border:1px solid transparent;
    background:
      conic-gradient(from 90deg at top 1px left 1px,#0000 90deg,#fff 0) 0 0,
      conic-gradient(from -90deg at bottom 1px right 1px,#0000 90deg,#fff 0) 100% 100%;
    background-size:5px 5px;
    background-origin:border-box;
    background-repeat:no-repeat;
}

.bubble.right div:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 0.5em solid transparent;
	border-left-color: rgba(0,0,0,.75);
	border-right: 0;
	margin-top: -0.5em;
	margin-right: -0.5em;
}

.bubble.bottom:after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 1.5rem;
	width: 0;
	height: 0;
	border: 0.5em solid transparent;
	border-top-color: rgba(0,0,0,.75);
	border-bottom: 0;
	margin-left: -0.5em;
	margin-bottom: -0.5em;
}

@keyframes fadeOut{
    100%{
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes bounceIn{
    0%{
      opacity: 0;
      transform: scale(0.3) translate3d(0,0,0);
    }
    50%{
      opacity: .85;
      transform: scale(1.15);
    }
    80%{
      opacity: 1;
      transform: scale(0.89);
    }
    100%{
      opacity: .95;
      transform: scale(1) translate3d(0,0,0);
    }
}

@keyframes bounceOut {
    0% {
       transform: scale(1) translate3d(0,0,0);
    }
    25% {
       transform: scale(.95);
    }
    50% {
       opacity: 1;
       transform: scale(1.15);
    }
    100% {
       opacity: 0;
       transform: scale(.3) translate3d(0,0,0);
    }
} 