.item-wrapper{
    width:100%;
}

.item{
    color:#8e8e8e !important;
    font-size:.85rem;
    border:0;
    position:relative;
    height:fit-content;
    width:100%;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    text-decoration: none;
    border-radius: 0 !important;
    border:1px solid rgba(0,0,0,.55);
    background: linear-gradient(320deg, #212121 50%, transparent 50%) !important;
    transition: all .15s ease-in-out !important;
}

.item:hover .image{
    filter:grayscale(0);
    
}

.item .image{
    position:relative;
    width:100%;
    height:170px;
    z-index:9995;
    background-size: cover !important;
    background-position:center;
    transition: all .1s ease !important;
    filter:grayscale(1);
}

.item .text{
    display:flex;
    flex-direction:column;
    position:relative;
    z-index:9998;
    height:100%;
    width:100%;
    justify-content: center;
    font-family:'Orbitron',sans-serif;
    font-size:.75rem;
    padding:0 !important;
}

.item .text h3{
    background-color: #009688;
    color:#fafafa !important;
    padding:.5rem;
    margin:0;
    font-weight: 400;
    font-size:.85rem;
}

.item .text h3,
.item .text span:nth-child(n+2){
    font-size: .85rem !important;
    color:#000;
    padding:.5rem;
    margin-top:0;
}

.item .text span{
    line-height: 1rem;
    background:#000;
    min-height:3rem;
    overflow-wrap: break-word;
}