.wrap{
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:calc(100vh - 130px);
}

.small{
    position:relative;
    width:100vw;
    height:400px;
}

.track-wrapper {
    position:relative;
    min-height:calc(100% + 100px);
    height:100%;
    width:100%;
    box-sizing: border-box;
    overflow: hidden;
}

.small-wrapper{
    position:relative;
    margin-top: 4rem;
    height:100%;
}

.button{
    border:2px solid #eee !important;
    color:#fff !important;
    margin:.5rem 0 0 auto !important;
    font-weight: 400;
    transition: background-image 5s ease-in-out;
    max-width:200px;
    z-index:9998;
    display:none !important;
}

.button:hover{
    border:2px solid #fff;
    color:#fff;
}

@media only screen and (min-width: 1200px) {
    .track-wrapper {
        min-height:unset !important;
    }
    .button{
        display:block !important;
    }
}