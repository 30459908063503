.footer{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding:1.5rem 0 1rem 0;
    color:#8e8e8e;
    font-size:.85rem;
}

.footer .icons{
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content:flex-end;
}

.footer p{
    margin-bottom:1rem;
}

.footer i{
    display:inline-block;
    text-align: center;
    text-decoration: none;
    font-size:1rem;
    width:2rem;
    height:2rem;
    padding:.45rem .35rem;
    border:2px solid;
    border-radius:50%;
    margin: 0 .7rem 0 0;
    color:inherit;
    transition: .15s ease-in-out;
}

.footer img{
    height:48px;
    margin:.4rem .7rem 0 0;
}

.footer .copyright{
    display:flex;
    color:#eeeeee;
    /*font-size:1rem;*/
    font-size:.7rem;
    margin-top:.25rem;
    margin-left:auto;
    justify-content: flex-end;
}

.footer a{
    color:inherit;
    text-decoration: none;
    transition: color .2s ease-in;
}


.footer a i{
    text-decoration: none;
    transition: all .2s ease-in;
}

.footer a:not(.logo):hover{
    color:#009688;
}

.footer a:hover i{
    color:#f9f9f9;
    border-color:#009688;
}

.footer .logo{
    /*font-family: 'Press Start 2P', cursive;*/
    font-family: 'Mechsuit', sans-serif;
    font-weight: 700;
    margin-right:.5rem;
    padding:.2rem .25rem 0 .25rem;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    color:#f9f9f9;
    transition: all .5s ease-in-out;

}

.footer .logo:hover{
    border-top:5px solid #009688;
    border-bottom:5px solid #009688;
}

.link-container{
    display:flex;
    flex:1 1 auto;
    height:100%;
    justify-content:flex-start;
    align-items: flex-end;
}
.footer .terms{
    /*font-size:1.25rem;*/
    font-size:.85rem;
    margin-right:1rem;
}

.svg i {
    padding:0;
}
.svg svg{
    display:block;
    margin: auto;
    fill:#8e8e8e;
}
.footer a:hover svg{
    fill:#f9f9f9;
}

