.menu-button{
    padding:1rem 0 0 1rem;
}

.stuff-container > div{
    padding: 0 0 1rem 2rem;
}

.faq-container{
    margin-top:3rem;
    margin-left:1.25rem;
}

.pad{
    padding:2rem 3rem 0 2rem;
}


@media (max-width: 900px) {
    .faq-container{
        margin-top:0;
        min-height:unset !important;
    }
    .pad{
        padding:2rem 1rem;
    }    
}