.distance {
    position:absolute;
    right:0;
    top:0;
    z-index:999;
    padding:.25rem .5rem;
    font-size:.75rem;
    line-height: .75rem;
    font-weight: 600;
    background-color: rgba(255,193,7,.75);
    color:#121212;
}

.progress {
    background-color: rgba(230,174,6,0)!important;
}

.progress > span {
    background-color: #121212 !important;
}

.position-wrapper{
    position:absolute;
    top:2rem;
    left:calc(50% - 150px);
    display:flex;
    flex-direction: column;
    z-index: 999;
    align-items: center;
    justify-content: center;
}

.position-wrapper > span{
    font-size: 1rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.line-wrapper{
    display:flex;
    flex-direction: row;
    font-size: .55rem;
    font-weight: 300;
    align-items: center;
}
.line-wrapper span{
    font-family: 'Tech',sans-serif;
    color:#f9f9f9;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;  
    margin-left:5px;
    line-height: .75rem;
}

.line{
    width:300px;
    height:6px;
    border-radius:10px;
    border:1px solid rgba(0,0,0,.5);
    background:rgba(255,255,255,.25);
}

.line > div{
    position:absolute;
    top:calc(50% + 5px);
    left:0;
    border:3px solid #E6AE42;
    box-shadow: 0 0 0 1px rgba(0,0,0,.5);
    outline: 1px solid rgba(0,0,0,.5);
    outline-offset: -3px;
    background-color: transparent;
    border-radius: 50%;
    height:10px;
    width:10px;
    z-index: 999;
    transition: all 7s ease-out;
}


@media only screen and (max-width: 600px) {
    .position-wrapper{
        top:0 !important;
        left:50% !important;
        transform: translateX(-50%) !important;
    }
}