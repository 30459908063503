/* ---------- Fog ---------- */
.fog-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
    filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
    z-index:998;
  }
  .fog-layer-1, .fog-layer-2, .fog-layer-3 {
    height: 100%;
    position: absolute;
    width: 200%;
  }
  .fog-layer-1 .cloud-1, .fog-layer-1 .cloud-2,
  .fog-layer-2 .cloud-1, .fog-layer-2 .cloud-2,
  .fog-layer-3 .cloud-1, .fog-layer-3 .cloud-2 {
    float: left;
    height: 100%;
    width: 50%;
  }

  .fog-layer-1 {
    animation: fog-layer-1_opacity 10s linear infinite, foglayer_moveme 5s linear infinite;
  }
  .fog-layer-2, .fog-layer-3 {
    animation: fog-layer-2_opacity 21s linear infinite, foglayer_moveme 2s linear infinite;
  }

  .pending .fog-layer-1,
  .hit-the-breaks .fog-layer-1{
    animation: fog-layer-1_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;  
  }
  .pending .fog-layer-2,
  .pending .fog-layer-3,
  .hit-the-breaks .fog-layer-2,
  .hit-the-breaks .fog-layer-3 {
    animation: fog-layer-2_opacity 21s linear infinite, foglayer_moveme 12s linear infinite;  
  }


  .fog-layer-1 .cloud-1, .fog-layer-1 .cloud-2 {
    background: url("../../../assets/images/fog1.png") center center/cover no-repeat transparent;
  }
  .fog-layer-2 .cloud-1, .fog-layer-2 .cloud-2,
  .fog-layer-3 .cloud-1, .fog-layer-3 .cloud-2{
    background: url("../../../assets/images/fog2.png") center center/cover no-repeat transparent;
  }
  
  /* ---------- Keyframe Layer 1 ---------- */
  @-webkit-keyframes fog-layer-1_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  @-moz-keyframes fog-layer-1_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  @-o-keyframes fog-layer-1_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  @keyframes fog-layer-1_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  /* ---------- Keyframe Layer 2 ---------- */
  @-webkit-keyframes fog-layer-2_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  @-moz-keyframes fog-layer-2_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  @-o-keyframes fog-layer-2_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  @keyframes fog-layer-2_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  /* ---------- Keyframe Layer 3 ---------- */
  @-webkit-keyframes fog-layer-3_opacity {
    0% { opacity: .8 }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  @-moz-keyframes fog-layer-3_opacity {
    0% { opacity: .8 }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  @-o-keyframes fog-layer-3_opacity {
    0% { opacity: .8 }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  @keyframes fog-layer-3_opacity {
    0% { opacity: .8; }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  /* ---------- Keyframe moveMe ---------- */
  @-webkit-keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @-moz-keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @-o-keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  
  @media only screen
    and (min-width: 280px)
    and (max-width: 767px) {
      .fog-layer-1 .cloud-1, .fog-layer-1 .cloud-2,
      .fog-layer-2 .cloud-1, .fog-layer-2 .cloud-2,
      .fog-layer-3 .cloud-1, .fog-layer-3 .cloud-2 {
        width: 100%;
      }
    }