.player{
    color:#8e8e8e !important;
    font-size:.85rem;
    margin:0 1rem 1rem 0;
    border:0;
    position:relative;
    height:fit-content;
    width:170px;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    text-decoration: none;
    border-radius: 0 !important;
    border:1px solid rgba(0,0,0,.55);
    background: linear-gradient(320deg, #212121 50%, transparent 50%) !important;
    transition: all .15s ease-in-out !important;
    z-index: 999 !important;
}

.player:hover .image{
    filter:grayscale(0);
    
}

.player .image{
    position:relative;
    width:100%;
    height:170px;
    z-index:9995;
    background-size: 300% !important;
    background-position:90% 30%;
    transition: all .1s ease !important;
    filter:grayscale(1);
}
.heading{
    background: rgb(230,174,6);
    color:#000;
    padding: 0 .25rem;
    text-align: center;
    font-weight:500;
}

.player .text{
    display:flex;
    flex-direction:column;
    position:relative;
    z-index:9998;
    height:100%;
    width:100%;
    justify-content: center;
    font-family:'Orbitron',sans-serif;
    font-size:.75rem;
    padding:0 !important;
}

.player .text h3{
    background-color: #009688;
    padding:.5rem;
    margin:0;
    color:#fff;
    font-weight: 400;
    font-size:.85rem;    
}

.player .text h3,
.player .text span:nth-child(n+2){
    font-size: .85rem !important;
    color:#f9f9f9;
    padding:.5rem;
    margin-top:0;
}

.player .text span{
    line-height: 1rem;
    background:#000;
    min-height:3rem;
    overflow-wrap: break-word;
}

.player.small{
    flex-direction:row;
}

.player.small.highlight{
    animation: animated-glow 1s ease-in-out infinite alternate;
    background: #212121 !important;
}

.player.small .text,
.player.small .image{
    height:100px;
}
.player.small .text{
    display: flex;
    background-color: #009688;
    border:1px solid #009688;
    align-items: center;
}
.player.small span{
    display:none;
}

@keyframes animated-glow {
	0% {
		box-shadow: 0 0 1rem .5rem #fff;
	}
	100% {
		box-shadow: initial;
	}
}
