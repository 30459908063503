span.title{
    display:block;
    margin: .5rem 0;
    font-weight: 600;
    text-transform: uppercase;
}

.tooltip{
    font-size:.85rem !important;
    background-color:rgba(0,0,0,.35) !important;
    font-family: 'Tech',sans-serif !important;
    margin-top:.5rem !important;
}
.tooltip-arrow{
    color:rgba(0,0,0,.35) !important;
}

.attributes{
    display:inline-flex;
    flex-direction: column;
    max-width:100px;
    justify-items: center;
    align-items: center;
    border-radius: 0;
}

.attributes div{
    background-size: contain;
    background-position: center;
    height:100px;
    width:100px;
}
.attributes span{
    font-family: 'Tech',sans-serif;
    text-transform: capitalize;
    color:#f9f9f9;
    font-size: .85rem;
}
.attributes span.equipped{
    color:#009688;
    font-size: .75rem;
    text-transform: uppercase;
}
.attributes .link{
    color:#f9f9f9 !important;
}
.attributes .link:hover{
    color:#fff !important;
    background-color: #212121 !important;
}

.attributes,
.attributes:nth-of-type(9){
    animation: floating 15s infinite ease-in-out;
}
.attributes:nth-of-type(2),
.attributes:nth-of-type(10){
    animation: floating2 20s infinite ease-in-out;
}
.attributes:nth-of-type(3),
.attributes:nth-of-type(11){
    animation: floating3 20s infinite ease-in-out;
}
.attributes:nth-of-type(4),
.attributes:nth-of-type(12){
    animation: floating4 15s infinite ease-in-out;
}
.attributes:nth-of-type(5),
.attributes:nth-of-type(13){
    animation: floating5 20s infinite ease-in-out;
}
.attributes:nth-of-type(6),
.attributes:nth-of-type(14){
    animation: floating6 15s infinite ease-in-out;
}
.attributes:nth-of-type(7),
.attributes:nth-of-type(15){
    animation: floating7 15s infinite ease-in-out;
}
.attributes:nth-of-type(8),
.attributes:nth-of-type(16){
    animation: floating8 15s infinite ease-in-out;
}

.attributes span:first-child{
    color:#f9f9f9;
    font-size:.8rem;
    font-weight: 600;
    text-transform: uppercase;
}


.link.disabled{
    position:relative;
    pointer-events: none !important;
}
.link.disabled:after{
    background-color:#212121;
    position:absolute;
    width:100%;
    height:100%;
    padding-top:50%;
    content:'Processing...';
    font-size:.75rem;
    color:#f9f9f9;
    text-align: center;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 5px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating2 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-5px, 10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating3 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-10px, 5px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating4 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(5px, 5px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating5 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-5px, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating6 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(5px, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating7 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating8 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-10px, 0px); }
    to   { transform: translate(0, -0px); }    
}