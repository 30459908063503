@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;900&display=swap');
@font-face{
    font-family:"Garde";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/garde.otf") format('opentype');
    src:url('https://chainhounds.s3.amazonaws.com/webfonts/garde.woff') format('woff');
    font-weight:400;
    font-display:swap;
}
@font-face{
    font-family:"Primal";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/primal.otf") format('opentype');
    src:url('https://chainhounds.s3.amazonaws.com/webfonts/primal.woff') format('woff');
    font-weight:400;
    font-display:swap;
}
@font-face{
    font-family:"Mandatory F";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/Mandatory-font.otf") format('opentype');
    font-weight:400;
    font-display:swap;
}
@font-face{
    font-family:"Tech";
    src:url("https://chainhounds.s3.amazonaws.com/webfonts/ShareTechMono-Regular.ttf");
    font-weight:400;
    font-display:swap;
}

body{
    font-family: 'Tech', sans-serif !important;
    background-color:#121212 !important;
    color:#eee !important;
    font-size:1rem !important;
    line-height:1.25rem !important;
    box-sizing: border-box;
    display: flex;
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
}

#root{
    display: flex;
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
}

main {
    width:100%;
    height: 100%;
    flex: 1 0 auto;
}

.MuiButton-root{
    text-transform: unset;
}

a{
    color:#E6AE42;
    transition: color .2s ease-in;
    text-decoration: none;
}
.link{
    color:#E6AE42 !important;
    transition: color .2s ease-in !important;
    text-decoration: none !important;
}
a:hover{
    color:#F8913E;
}
.link:hover{
    color:#F8913E !important;
}

.MuiButton-containedPrimary{
    background-color:#009688;
    color:#fff;
}
.button{
    margin-right:5px !important;
    margin-bottom: 5px !important;
}
.button,
.input input + button{
    background-color:#009688 !important;
    color:#fff !important;
}

.MuiButton-containedPrimary:hover{
    background-color:#00afb9;
}
.button:hover,
.input input + button:hover{
    background-color:#00afb9 !important;
}

.button{
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding:0 .75rem !important;
}
.button:not(:last-child){
    margin-right: .25rem !important;
}

.input{
    margin:1rem auto;
    padding-right:0;
}
.input input{
    font-size: 1rem;
    padding-right:1rem;
    background:rgba(0,0,0,.25);
}
.input fieldset{
    border-width: 2px;
    border-color: #f9f9f9 !important;
}
.input fieldset > legend{
    font-size: .75rem;
}
.label{
    margin-top:1rem;
    font-size: 1rem !important;
    color:#f9f9f9 !important;
}

.container{
    padding-top:3rem;
}

h1.title{
    font-family: 'Garde', sans-serif;
    font-size:4rem;
    line-height:4.5rem;
    margin:2rem 0;
    text-transform: uppercase;
    color:#f9f9f9;
}

.full-menu{
    display:flex;
    position:relative;
    overflow:hidden;  
    min-height:calc(100vh - 135px);
}
.full-menu.white{
    background: #bdbdbd;
    background: linear-gradient(180deg, #bdbdbd 0%, #121212 100%);
}
.full-menu.black{
    background: #000;
}

::-webkit-scrollbar{
    background-color: rgba(0,0,0,.75);
    width: .35rem;
}
::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0,0,0,.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.5);
}
::-webkit-scrollbar-thumb{
    background-color: #bdbdbd;
    outline: 1px solid #bdbdbd;
    border-radius: 0;
}
.gm-style-cc { display:none; }


.noisy-bg-container{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    overflow:hidden;
}

.noisy-bg{
    position:absolute;
    top:-20%;
    left:-20%;
    height:150%;
    width:150%;
    background:url(../../assets/images/noise-transparent.png) repeat -20% -20%;
    animation: bg-animation 1s infinite;
    opacity: .9;
    visibility: visible;
    pointer-events: none;
}

.invisible{
    opacity:0;
}
.slide-down {
    opacity: 0;
    animation: slide-down-animation .5s cubic-bezier(.19,0,.37,1.15) forwards;
}
.slide-left {
    opacity: 0;
    animation: slide-left-animation .5s cubic-bezier(.19,0,.37,1.15) forwards;
}

@keyframes bg-animation {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,-15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(-15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}

@keyframes slide-down-animation {
    0% {
        transform: translateY(-1200px) scale(1);
        opacity: 0.1;
      }
    
      80% {
        transform: translateY(0px) scale(1);
        opacity: 0.7;
      }
    
      100% {
        transform: scale(1);
        opacity: 1;
      }
}


@keyframes slide-left-animation  {
    0% {
      transform: translateX(-1000px) scale(1);
      opacity: 0.1;
    }
  
    80% {
      transform: translateX(0px) scale(1);
      opacity: 0.7;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
}

@media (max-width: 900px) {
    .container{
        padding-top:0;
    }
    .full-menu{
        min-height:calc(100vh - 415px);
    }    
}