.container{
    display:flex;
    flex-direction: row !important;
    background-color: transparent !important;
    background-image: unset !important;
    box-shadow: none !important;
    justify-content: center !important;
    width: 100%;
}
.container > div:first-child{
    width: 80%;
    margin-right:1rem;
    align-items: center;
}
.container > div:first-child > button:last-of-type{
    margin-right:0.4rem;
}
.container > div:last-child{
    width: 20%;
    align-items: center;
}

.icon{
    color:rgba(0,0,0,.85) !important;
    font-size:.75rem !important;
    transition: all .25s ease-in-out;
    width:1.5rem;
    height:1.5rem;
}

.icon:hover{
    color:rgba(0,0,0,1) !important;
}

.tooltip{
    background-color:rgba(0,0,0,.35) !important;
    font-family: 'Tech',sans-serif !important;
    margin-top:.5rem !important;
}
.tooltip-arrow{
    color:rgba(0,0,0,.35) !important;
}