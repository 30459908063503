.hero{
    position:relative;
    border-radius:0rem !important;
    margin:0;
    min-height:320px;
    overflow:hidden;
}

.hero.shop-closed{
    min-height:480px;
    background-color:transparent !important;
    background:url(../../assets/images/shop-closed.jpg) no-repeat center 90%;
    background-size: cover;
}
.hero.shop-open{
    min-height:320px;
    background-color:transparent !important;
    background:url(../../assets/images/shop-open.png) no-repeat center 30%;
    /*background:url(https://chainhounds.s3.amazonaws.com/parts/12-2.png) no-repeat center 25%;*/
    background-size: cover;
}


.hero-border{
    mask-image: url(../../assets/images/menu.svg);
    mask-position: top left;
    mask-repeat: repeat-x;
    height: 23px;
    width: 100%;
    background-color: #e0e0e0;
    margin-bottom:2rem;
}

.menu-container{
    margin:3rem 1.25rem !important;
}

@media (max-width: 900px) {
    .menu-container{
        margin:0 1.25rem 2rem 1.25rem !important;
        min-height:unset !important;
    }
}

@media (min-width: 1200px) and (max-width: 1536px) {
    .hero.shop-open {
        min-height:100px;
    }
}