.race-details {
    width:100%;
    background-color: transparent !important;
    background-image: unset !important;
    margin:0 1rem !important;
    font-size:1rem !important;
}

.race-details.active {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)) !important;
}

.race-details.active .race-name {
    padding-left: 2rem;
    transition: all .2s ease-in-out;
}

.race-details.active .race-name > span{
    color:#00B9B4;
    font-size:1rem;
}


.race-details > div{
    padding:0 !important;
    font-size:1rem !important;
    transition: all .2s ease-in;
}

.race-details > div:hover{
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)) !important;
}


.race-details b{
    display:block;
    width:100%;
    font-weight:500;
    font-size:1.2rem;
    text-align:center;
}
/*
.race-details a:not(.avatar,.link){
    display:block;
    text-align: center;
    border-radius:0;
    background-color:#43a047;
    color:#eee;
    text-decoration:none;

    
}

.race-details a:not(.avatar,.link):hover{
    background-color:#4caf50;
    color:#eee;
}
*/

.link{
    text-decoration: none;
}

.coin{
    font-size: .75rem;
    font-weight: 300;
    margin-left:.15rem
}

.race-details .summary{
    padding:0 !important;
    display:flex;
    justify-content: center;
    background-color:rgba(0,0,0,.25) !important;
    background-image:unset !important;
    
}

@media (max-width: 900px) {
    .race-details {
        margin:0 !important;
    }
}
