span.title{
    display:block;
    margin: .5rem 0;
    font-weight: 600;
    text-transform: uppercase;
}
span.title:not(:first-of-type){
    margin-top: 3rem;
}

.upgrades{
    background-color:#399CD1 !important;
    min-height:auto !important
}

.hackz{
    background-color:#DC358D !important;
    min-height:auto !important
}

.mason{
    position:relative;
    display:inline-flex;
    flex-direction: column;
    margin:1px;
    min-height:4.5rem;
    max-width:300px;
    justify-items: center;
    background-color:#424242;
    border-radius: 0;
    padding:1rem;
}
.mason span{
    display:block;
    text-transform: uppercase;
}
.mason span:first-of-type{
    font-weight: 600;
    text-transform: unset;
    font-size:.8rem;
}

.attributes{
    display:inline-flex;
    flex-direction: column;
    margin-right: 2rem;
    max-width:300px;
    justify-items: center;
    background-color:#424242;
    border-radius: 0;
    padding:1rem;
}

.attributes:after {
    --b:1px;
    --c:#f9f9f9;
    --w:5px;
    --g:#0000 90deg,var(--c) 0;
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;    
    border:var(--b) solid transparent;
    background:
      conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--g)) 0    0,
      /*conic-gradient(from 180deg at top    var(--b) right var(--b),var(--g)) 100% 0,*/
      /*conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--g)) 0    100%,*/
      conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--g)) 100% 100%;
    background-size:var(--w) var(--w);
    background-origin:border-box;
    background-repeat:no-repeat;
}

.attributes,
.attributes:nth-of-type(9){
    animation: floating 15s infinite ease-in-out;
}
.attributes:nth-of-type(2),
.attributes:nth-of-type(10){
    animation: floating2 20s infinite ease-in-out;
}
.attributes:nth-of-type(3),
.attributes:nth-of-type(11){
    animation: floating3 20s infinite ease-in-out;
}
.attributes:nth-of-type(4),
.attributes:nth-of-type(12){
    animation: floating4 15s infinite ease-in-out;
}
.attributes:nth-of-type(5),
.attributes:nth-of-type(13){
    animation: floating5 20s infinite ease-in-out;
}
.attributes:nth-of-type(6),
.attributes:nth-of-type(14){
    animation: floating6 15s infinite ease-in-out;
}
.attributes:nth-of-type(7),
.attributes:nth-of-type(15){
    animation: floating7 15s infinite ease-in-out;
}
.attributes:nth-of-type(8),
.attributes:nth-of-type(16){
    animation: floating8 15s infinite ease-in-out;
}

.attributes span:first-child{
    color:#f9f9f9;
    font-size:.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 5px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating2 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-5px, 10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating3 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-10px, 5px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating4 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(5px, 5px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating5 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-5px, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating6 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(5px, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating7 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating8 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-10px, 0px); }
    to   { transform: translate(0, -0px); }    
}