.container{
    overflow: hidden;
    position: relative;
    background-color: #bdbdbd;
    color:#000;
    z-index:99999 !important;
}

.list{
    border-radius: 0 !important;
    background-image: unset !important;
    background-color: transparent !important;
    padding:0 !important;
    max-height:calc(100vh - 150px);
    overflow-y: auto;
}

.items{
    font-family: 'Tech',sans-serif !important;
    font-size: .75rem !important;
    transition: all .25s ease-in-out;
    color: #000;
}
p.items{
    color:#616161;
}

.list-button{
    padding:.1rem .75rem !important;
}

.selected{
    color:#000 !important;
    background-color: rgb(230,174,6) !important;
}
.selected p.items{
    color:#fff;
}