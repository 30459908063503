
.preview {
    position:relative;
    width:350px;
    height:auto;
    font-size:.85rem;
    padding:1rem 0;
    background-color:rgba(0,0,0,.25) !important;
    background-image:unset !important;
    margin:0 auto;  
}

.preview .media{
    background-size: cover;
    min-height:250px;
    width:100%;
    height:150px;
    padding:1rem;
    cursor: pointer;
}
/*
.preview a{
    border:2px solid #f9f9f9;
    color:#fff;
    margin:0 .5rem;
    font-weight: 400;
    transition: background-image 5s ease-in-out;
    max-width:200px;
}

.preview a:hover{
    border:2px solid #fff;
    color:#fff;
}
.preview a:hover i{
    color:#fff;
}
*/

.progress {
    background-color: rgba(0,0,0,1) !important;
    margin-top:1rem;
    margin-bottom:.5rem;
    height:2px !important;
}

.progress > span {
    /*background-color:rgba(255,255,255,.05) !important;
    background-image: 
    repeating-linear-gradient(
      90deg,
      #388e3c,
      #388e3c 15px,
      rgba(255,255,255,.05) 14px,
      transparent 16px /* determines size *//*
    );*/
    background-color:#7ECC81 !important;
}

.log-content img {
    width:100%;
    height:auto;
}

.log-content{
    background-color:rgba(0,0,0,.75) !important;
    box-shadow: none !important;
    background-image:unset !important;   
    font-size:.85rem;
    color:#f9f9f9;
}

.log-content h2{
    font-size:1.25rem;
    margin:2rem 0 .5rem 0;
}


.log-content::-webkit-scrollbar{
    width: 0.4em;
}
.log-content::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(255,255,255,.1);
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,.1);
}
.log-content::-webkit-scrollbar-thumb {
    background-color: rgb(189,189,189);
    outline: 1px solid rgb(189,189,189);
    border-radius: .25rem;
}
.log-action{
    font-size:.85rem;
    color:#f9f9f9 !important;
    background-color:rgba(0,0,0,.25) !important;
    background-image:unset !important;   
}
.log-action .button{
    border:2px solid #f9f9f9;
    color:#f9f9f9;
    margin: 1rem .5rem 1rem 0;
    font-weight: 400;
    transition: background-image 5s ease-in-out;
    max-width:200px;
}

.log-action .button:hover{
    border:2px solid #f9f9f9;
    color:#f9f9f9;
}

.log-action .button:hover i{
    color:#f9f9f9;
}