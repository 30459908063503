.gallery-wrapper{
    width:100%;
}

.gallery{
    margin: 0 1rem 1rem 0;
    box-sizing: border-box;
    background-color:rgba(0,0,0,0) !important;
    background-image:unset !important;
    color:#8e8e8e;
    font-size:.85rem;
    width:100%;
    transition: all .5s ease-in-out;
    border-radius: 0 !important;
}
/*
.gallery:hover{
    background-color:rgba(0,0,0,.5) !important;
    box-shadow: 0 1rem 0 rgba(0,0,0,.5), 0 -1rem 0 rgba(0,0,0,.5);
    transition: all .2s ease-in-out;
}
*/
.gallery .media{
    min-height:205px;
    image-rendering: auto;
    background-size: contain;
}

.gallery .content{
    background-color:#009688;
    width:100%;
    padding:1rem !important;
    text-align: left;
    transition: all .2s ease-in-out;
}

.gallery:hover .content{
    background-color:#D6328A;
}

.gallery .content span{
    color:#f9f9f9;
    font-size:.75rem;
    line-height: .75rem;
}
.gallery .content h1{
    font-size:1rem;
    line-height: 1rem;
    font-weight: 400;
    color:#f9f9f9;
    margin:0;
}
