.logo,
.logo div{
    width:100% !important;
    margin:1rem auto !important;
}

.menu{
    background-color:#bdbdbd !important;
    background-image: unset !important;
    min-width: 250px !important;
}

.menu-list{
    padding:0 1rem !important;
}

.menu-list hr{
    border-color:#9e9e9e !important;
}
.menu-list hr:last-of-type{
    margin-bottom:1rem !important;
}

.menu-list a{
    margin: 1rem 0 !important;
    padding:0 1rem !important;
}

.menu-list,
.menu-list a span{
    font-size:1rem !important;
    color:#000 !important;
}

.menu-list a:hover,
.menu-list a.selected{
    background-color:rgb(230,174,6) !important;
    color:#000 !important;
}