.winner-wrapper{
    display:flex;
    flex-direction: column;
    padding:1rem;
    justify-content: center;
}

.winners{
    display:flex;
    flex-direction: row;
    padding:0;
    margin:0 0 .25rem 0;
    align-items: baseline;
}
.winners.me{
    background-color:rgba(0, 185, 180,.5) !important;
}

.winners span{
    padding:.35rem 0;
    width:fit-content;
    font-size:1.25rem;
    font-family: 'Orbitron',sans-serif;
    font-weight: 900;
    vertical-align: middle;
}
/*
.winners span:nth-child(1):before{
    content:'';
    font-family: 'Font Awesome 5 Pro';
    font-weight:600;
    font-size:1.25rem;
    content: '\f005';
    color:rgba(255,255,255,.65);
    text-shadow: 4px 3px 1px rgba(0, 150, 136,.5);
    margin-right:.5rem;
    z-index: -1;
}
*/
.winners span:nth-child(1){
    font-size:1.25rem;
    line-height:1rem;
    color:rgba(255,255,255,.75);
    font-family: 'Garde',sans-serif;
    font-weight: inherit;
    letter-spacing: -2px;
    z-index:9998;
    text-align: right;
    text-shadow: none;
    width:40px;
    margin-right:2px;
}




.winner{
    color:#eee !important;
    /*font-size:1.25rem;*/
    font-size:.85rem;
    margin:0 1rem 1rem 0;
    border:0;
    position:relative;
    height:120px;
    max-width:200px;
    box-sizing: border-box;
    text-decoration: none;
    transition: all .15s ease-in-out !important;
}

.winner:hover .image{
    opacity:.3;
}

.winner .image{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    opacity:.2;
    z-index:9995;
    transition: all .15s ease-in-out !important;
}
.winner .image:after{
    content:'';
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    z-index:9997;
    background:rgba(255,255,255,.25);

}

.winner .text{
    display:flex;
    flex-direction:column;
    position:relative;
    z-index:9998;
    /*font-size:1.25rem;*/
    font-size:.85rem;
    height:100%;
    width:100%;
    justify-content: center;
}

.winner .text h3,
.winner .text span:nth-child(n+2){
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin-bottom:.2rem;
}

.winner .text span{
    line-height: 1rem;
    font-size:.75rem;
}

.winner .text span:nth-child(n+6):before{
    content: "•";
    margin:auto 5px;
}

.winner .text h3{
    color:#fff;
    font-weight: 400;
}

.winner .place{
    position:absolute;
    top:0;
    right:0;
    background-color:rgba(21,21,21,.85);
    border-radius: 4px;
    /*font-size:1rem;*/
    font-size:.85rem;
    font-weight: 500;
}

.winner:nth-child(-n+3) .place{
    background-color:rgba(230,174,6,.85);
    color:black;
    font-weight: 600;
}
