.container{
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
    color:#eee !important;
}

h1.title{
    font-family: 'Orbitron', sans-serif;
    font-size:3rem;
    font-weight:700;
    margin:3rem 0;
    color:#f9f9f9;
    text-transform: uppercase;
}