h1.title{
    font-size:1.25rem;
    font-weight:500;
    margin-top:0;
    margin-bottom:0;
    color:#000;
}

.header{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    padding:.2rem 0 0 0;
    margin-left:auto;
    align-items: center;
    z-index:1000 !important;
    justify-content: right;
    /*background-color:rgba(0,0,0,.25) !important;*/
    background-color: transparent !important;
    background-image:unset !important;
    box-shadow: none !important;
    width:fit-content !important;
}

.header > div{
    padding:0 1rem 0 0;
    text-transform: uppercase;
    /*font-size:1.25rem;*/
    font-size:.65rem;
    line-height:1rem;
    font-weight:600;
    color:#000;
}

.header div > span{
    /*font-size:1.25rem;*/
    font-size:.75rem !important;
    text-transform: none;
    font-weight: 400;
    /*text-transform: uppercase;*/
    color:#000;
}

.header h1{
    flex: 1 1 100%;
    margin-top:.5rem;
}

.header .button{
    border:2px solid #eee;
    color:#fff;
    /*padding:.5rem .75rem !important;*/
    margin-right:.5rem !important;
    /*font-size:1.25rem !important;*/
    font-weight: 400;
    transition: background-image 5s ease-in-out;
    max-width:200px;
}

.header .button:last-child{
    margin-right:0 !important;
}

.header .button:hover{
    border:2px solid #fff;
    color:#fff;
}
.header button:hover i{
    color:#fff;
}
