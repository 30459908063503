h1.title{
    font-size:1rem;
    display:block;
    margin: .5rem 0;
    font-weight: 600;
    text-transform: uppercase;
}

.title-bar{
    display:block;
    font-size: .85rem !important;
    padding:1rem;
    word-wrap: break-word; 
}


.link.disabled{
    position:relative;
    pointer-events: none !important;
}
.link.disabled:after{
    background-color:#212121;
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    padding-top:50%;
    content:'Processing...';
    font-size:.75rem;
    color:#f9f9f9;
    text-align: center;
}