.container{
    display:flex;
    flex-direction: column !important;
    height:100vh;
    justify-content: center;
}
.container h1{
    font-size: 2rem;
    line-height:2rem;
    font-family: 'Primal',sans-serif;
    text-transform: uppercase;
}

.price{
    margin-left:.25rem;
    color:rgb(230,174,6);
}
.price span{
    font-size:.55rem;
    font-weight: 900;
    margin-left:1px;
}

.gallery-container{
    padding-top: 3rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.container button:hover .price{
    color:inherit;
}

.preview{
    width:100% !important;
    padding:0 !important;
}

.button-disabled{
    position:relative;
    pointer-events: none !important;
    opacity: 1;
}
.button-disabled:after{
    background-color:inherit;
    position:absolute;
    width:100%;
    height:100%;
    padding:.5rem;
    content:'Processing...';
    text-align: center;
}