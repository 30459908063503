.header-bar{
    position: relative;
    margin-bottom:0;
    background:#bdbdbd !important;
    background: linear-gradient(180deg, #212121 0%, #bdbdbd 100%);
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)) !important;*/
    color:inherit;
    z-index:1100 !important;
    padding:0 !important;
    min-height:130px;
    overflow:hidden;
    /*overflow:hidden;*/
}

.header-bar .titles{
    text-align:right;
    color:#000;
    margin: auto 0;
    align-items: center;
    width: 100%;
}

.header-bar .titles > h1{
    font-size: 2rem;
    line-height: 2rem;
    font-family: 'Primal',sans-serif;
    text-transform: uppercase;
}

.header-bar .titles > span{
    display:block;
    font-size: 1.25rem;
    font-family: 'Tech',sans-serif;
}

.header-bar .titles .component-wrapper{
    position:relative;
    display: flex;
    flex-direction: column;
}

.header-bar-container{
    display:flex;
    align-items: center;
    padding:.5rem 0 .5rem 0;
    height:fit-content !important
}

.header-bar .logo{
    display:flex;
    align-items: center;
    font-weight:900;
    font-size:1rem;
    color:#fff;
    text-decoration:none;
    align-items: center;
    margin:0 !important;
    width:fit-content;
}

.header-bar .logo h1{
    font-weight: 600;
    font-size:1.25rem;
    color:#eee;
    margin:auto 0;
}

.header-bar .logo div {
    display:inline-flex;
    margin: auto 0;
    /*margin-right:.5rem;*/
    height:31px;
    width:auto;
    /*background:url(../../../assets/images/logo-bg-small.png) no-repeat center left;*/
    background-size:contain;
    vertical-align:middle;
    justify-content: center;
    align-items: center;
    padding:.2rem 0 0 .3rem;
    margin:.5rem 0;
}

.header-bar .logo span {
    text-shadow:2px 2px 6px #757575,
    -1px -1px 0 #bdbdbd,  
     1px -1px 0 #bdbdbd,
     -1px 1px 0 #bdbdbd,
      1px 1px 0 #bdbdbd;
    font-family: 'Mandatory F', sans-serif !important;
    color: #000;
    font-size: 1.2rem;
}

.header-bar .logo span b {
    color: #000;
}

.header-bar .logo img{
    display:block;
    height:40px;
    margin:0;
}

.header-bar a.logo:hover{
    color:inherit;
}


.header-bar a:not(.logo){
    color:#f9f9f9;
    font-weight:600;
    text-decoration: none;
    margin:0;
    padding:0 .75rem;
    /*font-size:1.25rem;*/
    font-size:1rem;
    transition: all .25s ease-in;
    width:auto;
    min-width:auto;
    opacity:1;
    text-transform: uppercase !important;
}

.header-bar a:not(.logo,.selected):hover{
    background-color:#212121;
    color:#f9f9f9;
}
.header-bar a.selected{
    background-color:rgb(230,174,6);
    color:#212121;
}

.header-bar .tabs{
    max-width:525px;
    background:#000;
    width:auto;
    text-align: center;
}

.header-bar .indicator{
    background:transparent !important;
}

.header-bar .season{
    position:relative;
    display:flex;
    flex-direction: column;
    font-family: 'Mandatory F',sans-serif;
    font-weight: 500;
    background:rgb(255,193,7);
    /*background: linear-gradient(300deg, rgba(61,168,172,.85) 50%,  rgb(230,174,6,.85) 50%);*/
    color: #212121;
    padding:0.3rem 0.85rem;
    margin:auto;
    font-size:.85rem;
    line-height: 1rem;
    width:auto;
    text-align: center;
}

.header-bar .season:after {
    --b:1px;
    --c:#f9f9f9;
    --w:5px;
    --g:#0000 90deg,var(--c) 0;
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;    
    border:var(--b) solid transparent;
    background:
      conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--g)) 0    0,
      /*conic-gradient(from 180deg at top    var(--b) right var(--b),var(--g)) 100% 0,*/
      /*conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--g)) 0    100%,*/
      conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--g)) 100% 100%;
    background-size:var(--w) var(--w);
    background-origin:border-box;
    background-repeat:no-repeat;
}

.header-bar .season .small{
    font-size:.5rem;
    line-height: .75rem;
    font-weight: 300;
}

@media (max-width: 900px) {
    .header-bar{
        min-height:fit-content !important;
        margin-bottom:2rem;
    }

    .header-bar .tabs{
        max-width:auto;
    }

    .header-bar .tabs a{
        font-size:.7rem !important;
        padding:0 .5rem
    }
}