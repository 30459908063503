h1.title{
    font-family: 'Orbitron', sans-serif;
    font-size:2rem;
    font-weight:600;
    color:#f9f9f9;
}

.hero{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow:hidden;
    max-height:calc(100vh - 130px);
    position:relative;
}

.hero svg{
    position:relative;
    width:100vh;
    height:auto;
    overflow:hidden !important;
}

.hero svg path{
    fill:#212121;
}