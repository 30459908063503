.tree{
   min-height: 100vh;
   flex-grow: 1;
   max-width: 200px;
   overflow-y:auto;
   font-size: .85rem !important;
}

.tree .item-group{
    margin-left: 20px;
    padding-left: 1.125rem;
    background-image: linear-gradient(to bottom, rgba(0,150,136,.75) 50%, rgba(255, 255, 255, 0) 50%);
    background-position: left;
    background-size: 1px 10px;
    background-repeat: repeat-y;    
}

.tree .label{
    padding:0;
}

.tree i{
    color:transparent;
    margin-right:1rem;
    vertical-align: middle;
    top:50%;
    transform: translateY(-50%);
}

.tree .icon-stack{
    height:40px !important;
    line-height: 1;
    overflow: hidden;
    width:0 !important;
    transition: all .25s ease-in-out;
}
.tree .item-content{
    padding: 0 !important;
}
.tree .item-selected{
    background: rgba(230,174,6,.95) !important;
    color:#000;
}
.tree .item-selected .icon-stack{
    background-color: #009688;
    width:40px !important;
}
.tree .item-selected .icon-stack i{
    color:#eee;
}
.tree .icon-container{
    width:fit-content !important;
    margin:0 !important;
}

.tree .button{
    max-height: 40px !important;
    width:100% !important;
    color: inherit !important;
    padding:.5rem 0 !important;
    justify-content: flex-start;
}

.tree .button:hover{
    background: #212121 !important;
}
.tree .item-selected .button:hover{
    background: inherit !important;
}

.tree .item-selected .button{
    padding-left: .5rem !important;
}

.tile {
    background-color:#009688 !important;
    font-weight: 600 !important;
    color:#eee !important;
    min-height:200px;
    width:100%;
    transition:all .25s ease-in-out !important;
}
.tile-img{
    display:none;
}

.tile:hover {
    background-color:rgba(230,174,6,.95) !important;
    color:#000 !important;
}
/*
.tile:hover + .tile-img {
    position:absolute;
    display:block;
    bottom:-25%;
    right:-50%;
    mask-image: url(../../../assets/images/crate.svg);
    mask-repeat: no-repeat;
    mask-size:contain;
    mask-position:center;
    background-color: rgba(0,0,0,1);
    height:75%;
    width:75%;
    pointer-events: none;
}
*/


@media (max-width: 1199px) {
    .tree{
        min-height: unset !important;
    }
}