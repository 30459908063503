.track {
    box-sizing: border-box;
	  border-collapse: separate;
	  display: table;
    background:linear-gradient(to bottom,#757575, #212121);    
    image-rendering: auto;
    height:100%;
    width: 100%; 
    overflow:hidden;
    overflow-y: auto;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    z-index:997;
}


.track > div:nth-child(-n+3){
  position:absolute;
  top:0;
  left:0;
  right:0;
  content: "";
  display: block;
  height: 145px;
  width:100%;
}

.sky-container {
  display: flex !important;
  width:400% !important;
  box-sizing: border-box;
  overflow:hidden;
  z-index:995;
}
.sky-container:after {
  content:'';
  background-image:url(../../../../assets/tracks/nyc/border-top.png);
  background-size:100% 6px;
  position:absolute;
  bottom:0;
  left:0;
  width: 100%;
  height:6px;
  filter:invert(0.38);
}

.moving > .sky-container {
    animation-name: move-road;
    animation-duration: 10s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(1,1,.72,.95);
    animation-play-state: paused;
    /*animation: move-road 180s infinite linear forwards;*/
}

.sky-container > div {
    flex-basis: 2100px;
    width: 2100px;
    height:700px; 
    background-size: cover;
    background-repeat:no-repeat;
    background-position:center;
    filter:invert(0.47);
}

.sky-container > div:nth-child(1),
.sky-container > div:nth-child(4){
  background-image:url(../../../../assets/tracks/nyc/sky1.png);
}
.sky-container > div:nth-child(2) {
  background-image:url(../../../../assets/tracks/nyc/sky2.png);
}
.sky-container > div:nth-child(3) {
  background-image:url(../../../../assets/tracks/nyc/sky3.png);
}


.track-bg2{
  background: url(../../../../assets/tracks/nyc/background.png) 0 bottom repeat-x;
  background-size: contain;
  background-position: 0 bottom;
  z-index:995;
  top:1rem !important;
  min-width:100%;
  filter:brightness(0.45) invert(0.35);
}
.moving > .track-bg2 {
  animation-name: move-road-img;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-play-state: paused;
  /*animation: move-road-img 60s infinite linear forwards;*/
}


.track-bg3{
  background: url(../../../../assets/tracks/nyc/statue.png) 0 bottom no-repeat;
  background-size: 700px 145px;
  background-position:100vw bottom;
  top:1rem !important;
  min-width:100%;
  filter:brightness(0.15);
  z-index:995;
}
.moving > .track-bg3 {
  animation-name: move-statue-img;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-play-state: paused;
  /*animation: move-road-img 180s infinite linear forwards;*/
}

.track-bg4 {
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  content: "";
  display: block;
  height: 54px;
  width:100%;
  background-image: url(../../../../assets/tracks/nyc/border-bottom.png);
  background-size:700px 54px,cover;
  background-repeat: repeat-x;
  background-position-y:top;
  image-rendering: auto;  
  filter:brightness(0) blur(0);
  z-index:997;
}
.moving > .track-bg4 {
  animation-name: move-border-img;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(1,1,.72,.95);
  animation-play-state: paused;
   /*animation: move-road-img 1s infinite linear forwards;*/
   filter:brightness(0) blur(1px);
}

.startline,
.finishline {
	width: 200%;
	height: 100%;
  position: absolute;
  left:70px;
  background:url(../../../../assets/images/finish.png) repeat-y;
  background-size: contain;
  background-position: 100% bottom;
  margin-top:144px;
  filter:opacity(0.25) brightness(0.25) invert(0.35);
}

.finishline {
    left:90%;
    animation-name: move-finish-img;
    animation-duration: 10s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-play-state: paused;
}

@keyframes move-road {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes move-road-img {
  100% { background-position: -10% bottom;}
} 
@keyframes move-statue-img {
  100% { background-position: -5% bottom;}
}
@keyframes move-border-img {
  100% { background-position: -100% bottom;}
}
@keyframes move-finish-img {
  100% { background-position: 0% bottom;}
}

@media (max-width: 900px) {
  .track-bg4{
    display: none;
  }
}

/*

.track-bg1:before{
  border:1px solid green;
  background-image: url(../../../../assets/tracks/nyc/sky1.png);
  background-repeat: repeat-x;
  background-position:0 bottom;
  background-size: auto 100%;
  z-index:995;
}
.track-bg1{
  display:flex;
  flex-direction: row;
  background-image: url(../../../../assets/tracks/nyc/sky2.png);
  background-repeat: repeat-x;
  background-position:0 bottom;
  background-size: auto 100%;
  z-index:995;
}
.track-bg1:after{
  content:'';
  background-image: url(../../../../assets/tracks/nyc/sky3.png);
  border:1px solid red;
  background-repeat: repeat-x;
  background-position:0 bottom;
  background-size: auto 100%;
  z-index:995;
}
.moving > .track-bg1 {
  animation: move-road 20s infinite linear forwards;
}
.track-bg2{
  background: url(../../../../assets/tracks/nyc/background.png) 0 bottom repeat-x;
  background-size: 1400px;
  z-index:996;
}
.moving > .track-bg2 {
  animation: move-road-big 60s infinite linear forwards;
}
.track-bg3{
  background: url(../../../../assets/tracks/nyc/border-top.png) 0 bottom repeat-x;
  background-size: 700px;
  z-index:997;
}
.moving > .track-bg3 {
  animation: move-road 10s infinite linear forwards;
}



.track.hit-the-breaks:before {
    animation: move-road 2s 1 ease-out forwards;
}

.track.moving:after {
    animation: move-road-bottom 2s infinite linear forwards;
}
.track.hit-the-breaks:after {
    animation: move-road-bottom 2s 1 ease-out forwards;
}

.moving{
    animation: move-road 2s infinite linear forwards;
}

.hit-the-breaks{
    animation: move-road 2s 1 ease-out forwards;
}

.bunny {
    position:absolute;
    background-image: url(../../../../assets/images/bat.gif);
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center center;
    image-rendering: auto;
    width:50px;
    height:50px;
    z-index:998;
    top:40%;
    transition: all 3s linear;
    filter: brightness(0.35);
}

@keyframes move-road-bottom {
    100% { background-position: -700px top; }
} 

@keyframes move-road {
    100% { background-position: -700px bottom;}
} 

@keyframes move-road-big {
  100% { background-position: -1400px bottom;}
} 

@keyframes move-lines {
    100% { right: 80px; }
}

.startline,
.finishline {
	width: 15px;
	height: 100%;
  position: absolute;
  left:70px;
  animation: move-lines .1s ease-out forwards;
  background:url(../../../../assets/images/finish.png) repeat-y;
  background-size: cover;
  margin-top:160px;
}

.finishline {
    left:unset;
    right: -10px;
}

*/

