.container{
    text-align: center;
    /*
    background: #bdbdbd;
    background: linear-gradient(180deg, #bdbdbd 0%, #212121 100%);
    */
    padding:0;
    position:relative;
    overflow:hidden;
}

h1.title{
    /*font-family: 'Press Start 2P', cursive;
    font-size:2rem;*/
    font-family: 'Garde', sans-serif;
    font-size:3rem;
    font-weight:900;
    line-height: 4.8rem;
    margin-top:0;
    margin-bottom:0;
    color:#000;
    -webkit-text-stroke: 1px #006064;
    text-shadow:2px 0 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

  
h1.title:before,h1.title:after{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /*
  h1.title:before {
    animation: glitch 500ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
    transform: translate(-0.04em, -0.03em);
    opacity: 0.75;
  }
  
  h1.title:after {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
    transform: translate(0.04em, 0.03em);
    opacity: 0.75;
  }
  */
  
@keyframes glitch {
    20% {
        text-shadow:2px 0 0 #d81b60, -1px -1px 0 #00838f, 1px -1px 0 #ec407a, -1px 1px 0 #d81b60, 1px 1px 0 #d81b60;
    }
    40% {
        text-shadow:2px 0 0 #ad1457 , -1px -1px 0 #eee , 1px -1px 0 #00838f, -1px 1px 0 #ec407a , 1px 1px 0 #c2185b ;
    }
    60% {
        text-shadow:2px 0 0 #ad1457 , -1px -1px 0 #ad1457 , 1px -1px 0 #ad1457 , -1px 1px 0 #00838f, 1px 1px 0 #ec407a ;
    }
    80% {
        text-shadow:2px 0 0 #c2185b , -1px -1px 0 #c2185b , 1px -1px 0 #00838f, -1px 1px 0 #ec407a , 1px 1px 0 #c2185b ;
    }
    100% {
        text-shadow:2px 0 0 #d81b60, -1px -1px 0 #00838f, 1px -1px 0 #ec407a, -1px 1px 0 #d81b60, 1px 1px 0 #d81b60;
    }
}
