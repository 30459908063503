.wrap{
    position: relative;
    flex-direction: column !important;
    padding:0;
    margin:0;
    z-index:999 !important;
}

.small{
    margin:0 auto !important;
}
.board{
    width:100% !important;
    max-width: 220px;
    margin:1rem auto 0 2rem !important;
    background:transparent !important;
    background-image:unset !important;
    z-index: 999;
    box-sizing: border-box;
    padding: 0 !important
}

.board,
.board span,
.board p{
    font-family: 'Tech',sans-serif !important;
    font-size:.75rem !important;
    line-height: .75rem !important;
    color:#f9f9f9 !important;
    font-weight: 400 !important;
}

.small .board{
    max-width:calc(100% - 4rem);
    margin:2rem !important;
}

.board li{
    padding:.0;
    margin-top:.25rem;
    background-color:rgba(0, 185, 180,.25) !important;
    border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important;
}

.board li.me{
    background-color:rgba(0, 185, 180,.5) !important;
}

.board .pos{
    width:20px !important;
}

.board li a {
    width:100%;
    padding:.25rem .5rem;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    /*margin:.1rem 1rem;*/
}


.place{
    min-width: 45px !important;
    line-height: 1rem !important;
}
.place div{
    display:flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius:50%;
    /*background-color: #388e3c;*/
    background-color:rgba(0,0,0,.25);
    border:2px solid #8e8e8e;
    color: #8e8e8e;
    height:30px;
    width:30px;
}

.board li:nth-child(1) .place div,
.board li:nth-child(2) .place div,
.board li:nth-child(3) .place div{
    /*border:3px solid #DAA520;
    box-shadow: 0 0 5px #DAA520;*/
    font-weight: 900;
    background-color:#224F57;
    border:2px solid #07D3E5;
    color:#f9f9f9;
}

/*
.board li:nth-child(-n+3) span,
.board li:nth-child(-n+3) p{
    font-weight:600;
}
*/

.board .no-info{
    font-size: 1rem !important;
}

h1.title{
    font-family: 'Mandatory F', sans-serif;
    font-size:1.25rem;
    font-weight:600;
    color:#f9f9f9;
}

.position{
    margin-right: auto;
    margin-top:2.5rem;
    text-align: right;
    max-width: 200px;
    z-index: 999;
    margin-left:2rem;
    display:flex;
    font-size: 1rem;
    font-weight: 300;
    align-items: center;
    justify-content: center;
}

.position > div {
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.position label{
    display:block;
    font-size: .55rem;
    text-transform: uppercase;
    margin-top:auto;
    margin-right: .25rem;
}

.position span,
.position label {
    text-shadow: 2px 2px 5px #00B9B4;
}

.position span:first-of-type{
    font-size:3rem;
    font-weight: 600;
}