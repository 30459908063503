:root {
  --hound-width: 250px; /*120px*/
  --hound-height: 167px;
}

.racer-wrapper{
  width:min-content;
  display: inline-block;
  position:relative;
  align-items: center;
  z-index:997; 
}

.size-1{
  transform:scale(0.79);
}
.size-2{
  transform:scale(0.82);
}
.size-3{
  transform:scale(0.85);
}
.size-4{
  transform:scale(0.88);
}
.size-5{
  transform:scale(0.91);
}
.size-6{
  transform:scale(0.94);
}
.size-7{
  transform:scale(0.97);
}
.size-8{
  transform:scale(1);
}