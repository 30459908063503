.accordion{
    width:100%;
    background:transparent !important;
    color:#f9f9f9 !important;
    height:46px;
    margin:0 1rem !important;
}

.accordion > div:first-child > div {
    border-bottom: 1px solid rgba(255,255,255,.18);
    margin-bottom:0;
    padding-bottom:.75rem;
}

.pagination{
    margin-top:1rem;
    margin-left:auto;
}

@media (max-width: 900px) {
    .accordion > div{
        padding:0 !important;
    }
}