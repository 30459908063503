
.btn-connect{
    background-color:#009688 !important;
    color:#f9f9f9 !important;
    padding:1rem 2.75rem !important;
    font-size:1.25rem !important;
    margin:2rem .5rem !important;
}
.btn-connect:hover{
    background-color:#00B9B4 !important;
}

.btn-connect.small{
    padding:.25rem .75rem !important;
    font-size:.85rem !important;
    margin:0 !important;
    border-radius: 0 !important;
    min-height: 3rem !important;
}


.span-wallet{
    display:block;
    margin:2rem auto !important;
}


.menu{
    font-family: 'Primal',sans-serif !important;
    background-color: rgba(0, 0, 0,.95) !important;
    color:#fff !important;
    margin-right:.5rem !important;
    font-size:1rem !important;
    text-transform: none;
    height: auto;
    align-self: center;
    background-image: unset !important;
    padding:0 !important;
    border-radius: 0 !important;
}
.menu > ul{
    min-width: fit-content;
}
.menu-item{
    font-family: 'Primal',sans-serif !important;
    text-transform: uppercase;
    font-size:.85rem !important;
    padding:.75rem 1.5rem !important;
    margin:0 !important;
    transition: all .25 ease-in-out;
}
.menu-item:hover{
    background-color:rgb(230,174,6) !important;
    color: #000;
}
