.user-icon{
    display:flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    color:#f9f9f9;
    cursor:pointer;
    max-height:48px;
}
.user-icon > a{
    margin:0 !important;
}

.user-icon svg{
    fill:#f9f9f9;
    height:80px;
    width:75px;
    padding:0;
    margin-top:-1rem;
    margin-left:-1rem;
    min-width:auto;
    transition: all .2s ease-in;
}
.user-icon:hover svg{
    fill:#009688;
}

.user-icon a:after{
    font-family: 'Font Awesome 5 Pro';
    font-weight:600;
    font-size:.75rem;
    content: '\f078';
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
    right:.5rem;
    color:#9e9e9e;
    transition: all .2s ease-in;
}
.user-icon:hover a:after{
    color:#f9f9f9;
}

.user,
.avatar{
    height:36px;
    width:36px;
    border-radius: 50%;
    background-size:cover;
    background-position: top center;
}

.user{
    background-image: url(../../../../assets/images/ff.png);
}

.menu,
.disabled,
.balance {
    color:#f9f9f9 !important;
    /*font-size:1.2rem !important;
    text-transform: uppercase;*/
    font-size:1rem !important;

}

.disabled,
.balance{
    color:rgb(230,174,6) !important;
    opacity:1 !important;
}

.menu,
.disabled{
    text-transform: uppercase;
    font-size:1rem !important;
    font-weight: 600 !important;
}
.menu:hover {
    color:#000 !important;
    background-color:rgb(230,174,6) !important;
}

.disabled {
    /*font-size:1.25rem !important;*/
    font-size:1rem !important;
    text-transform: unset !important;
}
