h1.title{
    font-family: 'Mandatory F', sans-serif;
    font-size:2rem;
    padding: 1rem 0 2rem 0;
    font-weight:600;
    color:#f9f9f9;
}

.race-container{
    font-family: 'Mandatory F',sans-serif;
    font-size:.85rem;
    text-transform: uppercase;
    font-weight:500;
}

.selected{
    border:2px solid #00B9B4 !important;
    background-color: transparent !important;
}

.switch{
    margin:auto;
    margin-right:0;
}
.switch ~ span{
    font-family: 'tech', sans-serif;
    font-size:.85rem;
    color:#f9f9f9;
    margin-left:5px;
}
.switch .checked{
    color:#00B9B4 !important;
}


.switch .checked+.track{
    background-color: #00a19c !important;
}

.tab-container{
    display:flex;
    flex-direction: column;
    flex:1;
}

.indicator{
    background-color:#00B9B4 !important;
}
.v-indicator{
    width:1px !important;
}

.tab{
    font-size: 1.25rem;
}

.selected-tab{
    background-color: rgba(0,0,0,.18) !important;
}


@media (max-width: 900px) {
    .tab i{
        margin-right:0.5rem;
    }    
}