h1.title{
    font-family: 'Orbitron', sans-serif;
    font-size:2rem;
    font-weight:600;
    margin:2.32rem 0;
    color:#f9f9f9;
}

.kennel-container{
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.kennel-container > div {
    text-align: center;  
    margin: 5px;
}

.badge{
    width:100%;
    height:100%;
    z-index:9995;
    background-size: cover;
    background-position:center;
    box-shadow: none !important;
    background-image: unset !important;
}

.area-wrapper{
    align-items: center;
    justify-content: center;
    overflow:hidden;
    overflow-y: auto;
    height:calc(100vh - 130px);
    position:relative;
    padding-right:1rem !important;
    padding-left:2rem !important;
}

.area-wrapper h1.title{
    font-family: 'Orbitron', sans-serif;
    font-size:1.5rem;
    font-weight:600;
    color:#f9f9f9;
    margin:1rem auto;
}


@media (max-width: 1536px) {
    .area-prewrapper{
        display:none !important;
    }
    .area-wrapper {
        flex-basis:25% !important;
        max-width: 25% !important;
    }
}