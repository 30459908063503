.root{
    width:100vw;
    margin:0;
}
.error-alert{
    display:flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    color:#f9f9f9;
    background-color:#EF5146;
    border-radius:0;
    text-align: center;
    font-size:1rem !important;
    text-transform: uppercase;
    width:100%;
}
.error-message{
    margin-top:1.5rem;
}
.error-title{
    color:#f9f9f9;
    font-size:1.75rem !important;
}
