.faq-container{
    margin-top:3rem;
    margin-left:1.25rem;
}

.container{
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
    color:#f9f9f9 !important;
}

.pad{
    padding:2rem 3rem 0 2rem;
}

h2.title{
    font-size:1.5rem;
    font-family: 'Tech', sans-serif;
    text-transform: uppercase;
    font-weight:600;
    margin:1rem 0;
}

div.description,
div.description p{
    font-family: 'Tech', sans-serif;
    margin-top:0;
    color:#eee;
}

div.description a{
    color:inherit;
    text-decoration: none;
}
div.description a:hover{
    color:#00B9B4 !important;
    text-decoration: underline !important;
}

.highlight{
    position:relative;
    margin: 0 -0.4em;
    padding: 0.5em 0.6em;
    color:#f9f9f9;
}
  
.highlight:before{
    content:"";
    z-index:-1;
    left:0em;
    top:0em;
    border-width:2px;
    border-style:solid;
    border-color:#fff;
    position:absolute;
    border-right-color:transparent;
    width:100%;
    height:2em;
    transform:rotate(2deg);
    opacity:0.5;
    border-radius:0.25em;
  }
  
  .highlight:after{
    content:"";
    z-index:-1;
    left:0em;
    top:0em;
    border-width:2px;
    border-style:solid;
    border-color:#fff;
    border-left-color:transparent;
    border-top-color:transparent;
    position:absolute;
    width:100%;
    height:2em;
    transform:rotate(-1deg);
    opacity:0.5;
    border-radius:0.25em;
  }

.details,
.summary{
    padding-left:0 !important;
    font-size:1rem;
    line-height: 1.25rem;
}
.summary:hover{
    /*color:#81c784 !important;*/
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)) !important;
}

.xp-table{
    max-width:450px;
    margin:1rem auto;
}


.trk-table td,
.trk-table th,
.xp-table td,
.xp-table th{
    /*font-size: 1.25rem !important;*/
    text-align: right;
    text-transform: uppercase;
    font-weight:500;
    color:#8e8e8e;
}

.trk-table td:not(:nth-of-type(2)),
.trk-table th:not(:nth-of-type(2)){
    text-align:left;
}
.trk-table{
    margin:2rem auto;
}
.trk-table td{
    text-transform: unset;
}
.trk-table td:nth-of-type(1){
    white-space: nowrap;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    width:auto;
}

a.external:after{
    font-family: 'Font Awesome 5 Pro';
    font-weight:400;
    font-size:.65rem;
    margin-left:.25rem;
    display:inline-block;
    content:'\f08e';
}

a.internal:after{
    font-family: 'Font Awesome 5 Pro';
    font-weight:400;
    font-size:.65rem;
    margin-left:.25rem;
    display:inline-block;
    content:'\f0c1';
    vertical-align: middle;
}


@media (max-width: 900px) {
    .faq-container{
        margin-top:0;
        min-height:unset !important;
    }
    .pad{
        padding:2rem 1rem;
    }    
}