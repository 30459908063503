.accordion{
    width:100%;
    background:transparent !important;
    color:#f9f9f9 !important;
    height:46px;
}

.accordion > div:first-child > div {
    border-bottom: 1px solid rgba(255,255,255,.18);
    margin-bottom:0;
    padding-bottom:.75rem;
}

.race-container{
    font-size:.8rem;
    text-transform: uppercase;
    font-weight:500;
}

.pagination{
    margin-top:1rem;
    margin-left:auto;
}