.game-wrapper {
    position:relative;
    min-height:100%;
    height:fit-content;
}

.canvas{
    width:100vw;
    height:100%;
}

.pushy-container{
    display:flex;
    position:absolute;
    width: max-content;
    overflow: visible;
}

.pushy-container > div{    
    position: relative;
}

.pushy-container .pushy{
    width:1px;
    transition:all 7s linear;
}

@media (max-height: 600px) {
    .pushy-container {
        margin-top:40px;
    }
}