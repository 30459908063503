.map-container{
    position:relative;
}

.map-container svg{
    display:block;
    fill: #757575;
    min-width: 500px;
    max-width:1536px;
    margin: 0 0 0 auto;
}

.map-container .pin{
    padding:0 !important;
    margin:0 !important;
    min-width: auto !important;
    border-radius: 50% !important;
}

.map-container .pin div{
    width:32px;
    height:32px;
    background-image: url(../../../../assets/images/map-pin.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.map-container .nyc{
    position:absolute;
}

.drawer{
    width:100%;
    background-color: rgba(0,0,0,.15) !important;
    background-image:unset !important;
    height:auto !important;
    top:50% !important;
    transform: translateY(calc(50% * -1)) !important;
    max-height:100vh !important;
}


@media only screen and (min-width: 0) and (max-width: 600px) {
    .map-container svg{
        width:300px;
    }    
    .map-container .nyc{
        top:72px;
        left:120px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .map-container svg{
        width:600px;
    }    
    .map-container .nyc{
        top:98px;
        left:145px;
    }
    .drawer{
        width:80%;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .map-container svg{
        width:900px;
    }    
    .map-container .nyc{
        top:160px;
        left:230px;
    }    
    .drawer{
        width:50%;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1536px) {
    .map-container svg{
        width:1200px;
    }
    .drawer{
        width:50%;
    }

    /*
    .map-container .nyc{
        top:220px;
        right:calc(850px * 100 / 100vw);
    }
    */
}

@media only screen and (min-width: 1537px){
    .drawer{
        width:45%;
    }
}