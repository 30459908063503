/*
.session-descriptor{
    height:250px;
    position:relative;
    background:url(../../assets/images/season1.jpg) no-repeat center center;
    background-size:cover;
    padding:2rem;
}

.session-descriptor h1{
    font-family: 'Garde', sans-serif;
    font-size:3rem;
    font-weight:900;
    line-height: 4.8rem;
    width:fit-content;
    margin:1rem auto;
    color:#000;
    -webkit-text-stroke: 1px #006064;
    text-shadow:2px 0 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;    
}
*/

.hero{
    position:relative;
    border-radius:0rem !important;
    margin:0;
    min-height:250px;
    overflow:hidden;
}

.hero.season-1{
    background-color:transparent !important;
    background:url(../../assets/images/season1.jpg) no-repeat center 10%;
    background-size: cover;
}

.hero h1{
    position:absolute;
    bottom:0;
    font-family: 'Garde', sans-serif;
    font-size:3rem !important;
    font-weight:900 !important;
    line-height: 4.8rem;
    text-align: center;
    width: 100%;
    margin:1rem auto;
    color:#000;
    -webkit-text-stroke: 1px #006064;
    text-shadow:2px 0 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;    
}

.hero-border{
    mask-image: url(../../assets/images/menu.svg);
    mask-position: top left;
    mask-repeat: repeat-x;
    height: 23px;
    width: 100%;
    background-color: #e0e0e0;
}

.container p{
    white-space: pre-line;    
    color: #e0e0e0;
    line-height: 1.25rem;
    letter-spacing: 1px;
    font-size:1rem;
    margin-bottom:1rem;
    margin-right:1rem;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.container h1{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}