h1.title{
    font-family: 'Orbitron', sans-serif;
    font-size:2rem;
    font-weight:600;
    color:#f9f9f9;
    margin-top:0;
}

.area-wrapper{
    display: flex !important;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    height:calc(100vh - 130px);
    position:relative;
    padding:0 !important;
}

.board-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.board{
    width:100% !important;
    margin-left: auto !important;
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important;
    background-color:transparent !important;
    background-image: unset !important;
    box-shadow: none !important;
}

.board li{
    padding:0 .5rem 0;
    margin:.1rem 1rem;
}

.links{
    display:flex !important;
    color:inherit !important;
    text-decoration: none !important;
}
.links:hover{
    color:#81c784 !important;
}

.place div{
    display:flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius:50%;
    /*background-color: #388e3c;*/
    background-color:rgba(0,0,0,.3);
    color: #eee;
    height:30px;
    width:30px;
}

.board li:nth-child(1) .place div{
    /*border:3px solid #DAA520;
    box-shadow: 0 0 5px #DAA520;
    font-weight: 600;*/
    background-color: #388e3c;
}
.board li:nth-child(2) .place div{
    /*border:3px solid #C0C0C0;
    box-shadow: 0 0 5px #C0C0C0;
    font-weight: 600;*/
    background-color: #388e3c;
}
.board li:nth-child(3) .place div{
    /*border:3px solid #CD7F32;
    box-shadow: 0 0 5px #CD7F32;
    font-weight: 600;*/
    background-color: #388e3c;
}

.board li:nth-child(-n+3) span,
.board li:nth-child(-n+3) p{
/*    font-weight:600;*/
}


.winners{
    display:flex;
    flex-direction: row;
    padding:0;
    margin:0 0 .25rem 0;
    align-items: baseline;
}
.winners.me{
    background-color:rgba(0, 185, 180,.5) !important;
}

.winners span{
    padding:.35rem 0;
    width:fit-content;
    font-size:1rem;
    font-family: 'Orbitron',sans-serif;
    font-weight: 600;
    vertical-align: middle;
    overflow-wrap: break-word;
}

.winners span:nth-child(1){
    font-size:1rem;
    line-height:1rem;
    color:rgba(255,255,255,.75);
    font-family: 'Garde',sans-serif;
    font-weight: inherit;
    letter-spacing: -2px;
    z-index:9998;
    text-align: right;
    text-shadow: none;
    width:40px;
    margin-right:3px;
    white-space: nowrap;
    text-align: right;

}


.place-1,
.place-2,
.place-3,
.place-4,
.place-5,
.place-6,
.place-7,
.place-8{
    font-weight: 900;
    font-style: normal;
}

.place-1,
.place-2,
.place-3{
    background:rgb(230,174,6) !important;
    color:#000 !important;
}

.place-1:after{
    content:'1';
}
.place-2:after{
    content:'2';
}
.place-3:after{
    content:'3';
}
.place-4:after{
    content:'4';
}
.place-5:after{
    content:'5';
}
.place-6:after{
    content:'6';
}
.place-7:after{
    content:'7';
}
.place-8:after{
    content:'8';
}


.selected{
    border:2px solid #00B9B4 !important;
    background-color: transparent !important;
}

.tab-container{
    display:flex;
    flex-direction: column;
    flex:1;
}

.indicator{
    height:1px !important;
    background-color:#00B9B4 !important;
}

.tab{
    font-size: .85rem;
}

.no-info{
    font-family: 'Tech',sans-serif;
    font-size: .85rem;
    padding-top:1rem;
}