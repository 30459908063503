.button-container{
    width:auto;
    height:fit-content;
    display:flex !important;
    flex-direction: column;
    padding:2rem 0 0 2rem !important;
}

.buttons{
    display:flex;
    flex-direction: row;
    justify-content: flex-start !important;
    background: rgba(0,0,0,.95) !important;
    border-radius: 0 !important;
    padding-left:0 !important;
    margin-top:.25rem !important;
    height: 2.5rem;
    font-size: .85rem !important;
    font-weight: 600 !important;
    transition: all .25s ease-in;
}

.buttons:hover{
    background: #212121 !important;
}

.buttons.active{
    background: rgba(230,174,6,.95) !important;
    color:#000;
}

.buttons i {
    display:flex;
    margin-right:.75rem;
    height:2.5rem;
    width:2.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    background:#009688;
    color:#eee;
}

/*
.buttons.index-1 i{
    background:#F9913E;
}
.buttons.index-2 i{
    background:#3AA3B1;
}
.buttons.index-4 i{
    background:#BE88D2;
}
.buttons.index-3 i{
    background:#52A555;
}
*/


.menu{
    font-family: 'Primal',sans-serif !important;
    background-color: rgba(33,33,33,.99) !important;
    color:#fff !important;
    margin-right:.5rem !important;
    font-size:1rem !important;
    text-transform: none;
    height: auto;
    align-self: center;
    background-image: unset !important;
    padding:0 !important;
    border-radius: 0 !important;
}
.menu > ul{
    min-width: fit-content;
}
.menu-item{
    font-family: 'Primal',sans-serif !important;
    text-transform: uppercase;
    font-size:.85rem !important;
    font-weight: 400 !important;
    padding:.75rem 1.5rem !important;
    margin:0 !important;
    transition: all .25 ease-in-out;
}
.menu-item:hover,
.menu-item.active{
    background-color:rgb(230,174,6) !important;
    color: #000;
}

@media (max-width: 900px) {
    .button-container{
        padding-top:0 !important;
        padding-left:1rem !important;
    }
}