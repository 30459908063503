:root {
  --hound-width: calc(100vh * 0.40); /*250px*/ /*120px*/
  --hound-height: calc(100vh * 0.2664); /*167px*/
}

.all-wrapper{
  position:relative;
  min-width: 250px;
  min-height:167px;
  width: var(--hound-width);
  height:var(--hound-height);
  display:flex;
  margin-top:calc(var(--hound-height)*0.3);
  /*flex-direction: row-reverse;*/
  align-items: center;
}

.small-wrapper{
  position:relative;
  min-width: 24px;
  min-height:24px;
  display:flex;
  align-items: center;
  font-size: .85rem !important;
  transform:scale(1) !important;
}
.racer-ball{
  display: flex;
  position:absolute;
  height: 24px;
  width: 24px;  
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  align-items: center;
  justify-content: center;
}

.nameplate{
    display:flex;
    flex-direction: column;
    position:relative;
    margin:auto 0;
    background:transparent;
    width:auto;
    height:auto;
    padding:0;
    text-align: center;
}
.nameplate.me span{
  color:#FA913E !important;
}

.nameplate span{
  padding-top:.25rem;
  display:block;
  font-family: 'Tech',sans-serif;
  font-size:.75rem;
  font-weight:500;
  color:#f9f9f9;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.preload-race{
  position:absolute;
  top: -5000px;
  left: -5000px;
  background-size: contain;
  background-repeat:no-repeat;
  background-position:center;
}

.racer{
  position:absolute;
  top:0;
  left:0;
  display: block;
  min-width: 250px;
  min-height:167px;
  max-width: var(--hound-width);
  width: var(--hound-width);
  height: var(--hound-height);
  background-size: contain;
  background-repeat:no-repeat;
  background-position:center;
}

.racer:after{
  position:absolute;
  background-image:inherit;
  transform: rotateX(90deg);
  filter: brightness(0.1);
  transform-origin:50% 100%;
  backface-visibility:hidden;
  bottom:0;
  left:0;
  display: block;
}

.racer img{
  position:absolute;
  top:0;
  left:0;
  display: block;
  min-width: 250px;
  min-height:167px;
  max-width: var(--hound-width);
  width: var(--hound-width);
  height: var(--hound-height);
}


.animation {
  margin: 0 auto;
  max-width: var(--hound-width);
  overflow: hidden;
}

.sprite {
  position:absolute;
  height: var(--hound-height);
  width: var(--hound-width);
  background-size:calc(var(--hound-width) * 30) var(--hound-height);
  animation: sprite 850ms steps(30) infinite;
}
@keyframes sprite {
  to { background-position: calc(var(--hound-width) * -30) }
}

.hit-the-breaks .sprite:after{
  background-image:unset;
}


.avatar{
  height:36px;
  width:36px;
  background:#000;
  border-radius: 50%;
  margin:auto;
}

@media (max-height: 600px) {
  .all-wrapper {
      /*margin-top:0;*/
  }
}