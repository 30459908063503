.root{
    width:100vw;
    margin:0;
}
.success-alert{
    display:flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    color:#f9f9f9;
    background-color:#53A058;
    border-radius:0;
    text-align: center;
    font-size:1.25rem !important;
    text-transform: uppercase;
    width:100%;
}
.success-message{
    margin-top:2rem;
}
.success-title{
    color:#f9f9f9;
    font-size:1.75rem !important;
}
